import React, { useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import jammy from "../assets/projects/JIMMY RESIDENCE/1.jpg";
import roccatani from "../assets/projects/ROCCATANI DWELLING/1273 WALDMERE ROAD-01.jpg";
import andrew from "../assets/projects/ANDREW RESIDENCE/1.jpg";
import ramos from "../assets/projects/RAMOS KITCHEN/1-01.jpg";
import "../index.css";

function OurWork() {
  const elementsRef = useRef([]); // Store references to all elements we want to animate

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("slideUp"); // Add the animation class
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is in view
    );

    const currentElements = elementsRef.current;

    currentElements.forEach((el) => {
      if (el) {
        observer.observe(el);
      }
    });

    return () => {
      currentElements.forEach((el) => {
        if (el) {
          observer.unobserve(el);
        }
      });
    };
  }, []);

  const addToRef = (el) => {
    if (el && !elementsRef.current.includes(el)) {
      elementsRef.current.push(el); // Add the element to the reference array
    }
  };

  const projects = [
    {
      id: 1,
      src: jammy,
      title: "JIMMY RESIDENCE",
    },
    {
      id: 2,
      src: roccatani,
      title: "ROCCATANI RESIDENCE",
    },
    {
      id: 3,
      src: andrew,
      title: "ANDREW RESIDENCE",
    },
    {
      id: 4,
      src: ramos,
      title: "RAMOS KITCHEN",
    },
  ];

  return (
    <div className="container m-auto mt-[12rem] justify-center">
      <div className="sm:text-center">
        <div className="sm:m-auto sm:w-[500px]">
          <div className="flex items-center sm:justify-center sm:text-center">
            <div className="lg:w-16 w-10 h-[2px] mr-3 bg-slate-950 rounded-lg"></div>
            <span
              className="font-semibold tracking-wider text-[#292c37] text-sm sm:text-sm lg:text-xl uppercase transform-start"
              ref={addToRef} // Apply ref to this span
            >
              Our Recent Work
            </span>
          </div>
          <div className="w-auto h-auto">
            <h2
              className="mb-3 fontstyle text-left text-[18px] sm:text-[31px] md:text-[50px] textstyle mt-9 font-extrabold text-[#1d2434] sm:text-center lg:text-5xl transform-start"
              ref={addToRef} // Apply ref to this h2
            >
              OUR BEST RECENT WORK HERE
            </h2>
          </div>
        </div>

        <div className="m-auto sm:grid grid-cols-2 gap-[29px] lg:h-auto h-auto lg:my-14 md:my-14">
          {projects.map((project, id) => (
            <Link to="/projects" key={id}>
              <div
                className="group mb-8 transform-start"
                ref={addToRef} // Apply ref to this section
              >
                <img src={project.src} alt="" className="w-full lg:h-[20rem] md:h-[15rem] transition-transform duration-500 ease-in-out group-hover:scale-110" />
                <div className="child relative pb-8 pl-2 z-10 bg-white w-full transition-all group-hover:-translate-y-10 duration-500 ease-in-out">
                  <p className="text-left fontstyle text-[#1D1D1D] font-semibold text-sm lg:text-xl fontstyle">
                    {project.title}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <style>{`
        @keyframes slideUp {
          from {
            transform: translateY(50px); /* Start from a lower position */
            opacity: 0; /* Start as transparent */
          }
          to {
            transform: translateY(0); /* Move to normal position */
            opacity: 1; /* End as fully opaque */
          }
        }

        .slideUp {
          animation: slideUp 1s ease-out forwards; /* Add slideUp animation */
        }

        .transform-start {
          transform: translateY(50px);
          opacity: 0;
        }
      `}</style>
    </div>
  );
}

export default OurWork;
